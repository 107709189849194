<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <div class="min-heigth-61vh pt-5">
        <b-row>
          <b-col cols="12">
            <h3 class="text-center">
              Login
            </h3>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="6" offset="3">
            <b-form @submit.stop.prevent="submit">
              <b-form-group label="Email">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  placeholder="Email"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Senha">
                <b-form-input
                  v-model="form.password"
                  type="password"
                  placeholder="Senha"
                ></b-form-input>
              </b-form-group>
              <div class="mt-3 mx-auto container-button">
                <b-button variant="secondary" class="mr-2" @click="back">
                  Voltar
                </b-button>
                <b-button variant="info" @click="submit">
                  Enviar
                </b-button>
              </div>
            </b-form>
           </b-col>
         </b-row>
       </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import TokenService from '@/service/TokenService'

import Spinner from '@/components/Spinner'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: { Spinner, Header, Footer },

  data () {
    return {
      tokenService: null,
      spinner: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    back () {
      this.$router.push(`/`) 
    },
    submit () {
      this.spinner = true
      this.tokenService.authAdmin(this.form.email, this.form.password)
        .then(response => {
          if (response.status != null && response.status === 401) {
            Swal.fire({
              title: 'Atenção',
              text: 'Email ou Senha Incorretos!',
              icon: 'warning'
            })
          } else {
            localStorage.setItem('auth', response.token)
            localStorage.setItem('sgbAuth', response.sgbToken)
            Swal.fire({
              title: 'Sucesso',
              text: 'Login Efetuado com sucesso',
              icon: 'success'
            }).then(() => {
              this.spinner = true
              this.$router.push(`/admin`) 
            })
          }         
        })
        .finally(() => {
          this.spinner = false
        })
    }
  },
  mounted () {
    this.tokenService = new TokenService()
  }
}
</script>

<style>
  .min-heigth-61vh {
    min-height: 61vh;
  }

  body {
    background: #F5F5F5;
  }
  
  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>